























































import Vue from 'vue'
import api from '@/api'

import { Faq } from '@/types'

type FaqGroup = {
	name: Faq['eType']
	expanded: number[]
	faqs: Faq[]
}

export default Vue.extend({
	name: 'FAQ',
	data: () => ({
		groups: [] as FaqGroup[],
	}),
	created() {
		this.onFetchFaqs()
	},
	methods: {
		onClickGroup(group: FaqGroup) {
			const el = document.getElementById(group.name)
			if (el) {
				window.scrollTo({
					top: el.offsetTop,
					behavior: 'smooth',
				})
			}
		},
		async onFetchFaqs() {
			const [err, res] = await api.get('/faqs')
			if (err) return this.$toast.error(err.message || 'Error loading FAQs')

			this.groups = Object.keys(res.faqs).map((group) => ({
				expanded: [],
				faqs: res.faqs[group],
				name: group as Faq['eType'],
			}))

			this.$nextTick(() => {
				const eType = this.$route.hash.split('?')[0].split('#')[1]
				if (eType) {
					const faqGroup = this.groups.find(
						(group) => group.name === eType
					)
					if (faqGroup) this.onClickGroup(faqGroup)
				}
			})
		},
	},
})
